/* General myr-frontend-config style formatting. */

/* NOTE: z-index measures below are frequently necessary to be able to click
   floated elements when editing and sometimes when just clicking active
   elements (links, accordion boxes, etc).
 */

a {
  font-weight: 500 !important;
}

.documentFirstHeading:before {
  /* Remove red glitch that's part of standard Volto */
  border-bottom: none !important;
}
.documentDescription {
  margin: 0em 1em 1em 1em; /* Offset document description sides and bottom a little. */
}

a.external:after {
  content: '\2197'; /* ↗ U+2197 North East Arrow &UpperRightArrow; */
  opacity: 0.4;
}

/* Elevate the z-index of the stuff on the mobile screen navigation menu. */
.mobile-menu-nav,
.ui.secondary.menu .dropdown.item > .menu {
  /* Prevail over priorities we give to float entities in general.css. */
  z-index: 15;
}
.mobile-menu {
  /* Prioritize mobile menu over our special float entities. */
  z-index: 15 !important;
}
.ui.basic.segment .header .logo-nav-wrapper .logo,
.hamburger-wrapper {
  /* Include the close button and logo! */
  z-index: 16 !important;
}
.ui.secondary.pointing.menu .active.item {
  /* Nearly Volto site.variables "@linkColor" #007eb1. Use it directly? */
  border-color: steelblue !important; /* more radical: lime, aqua */
}

blockquote {
  padding-left: 0.5em;
  margin-left: 0.5em;
  border-left: 4px solid lavender;
  color: lightslategray;
}

.pullquote dd {
  /* Trim pullquoted navigation entry descriptions to two lines. */
  width: auto;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}
.pullquote {
  padding: 0 1em 0 1em;
  margin: auto;
  color: slategray;
  float: right;
  clear: right;
  z-index: 10;
  background-color: White;
  border-left: 2px solid lightgray;
}
@media only screen and (max-width: 800px) {
  .pullquote {
    width: 50%;
  }
}
@media only screen and (min-width: 801px) {
  .pullquote {
    width: 35%;
  }
}
.pullcontent {
  padding: 0 1em 0 1em;
  margin: 0 0 1em 1em;
  float: right;
  width: 35%;
  clear: right;
  z-index: 10;
  background-color: White;
}
.floatcontent-right {
  /* Same as .pullcontent but no width not specified */
  padding: 0 1em 0 1em;
  margin: auto;
  float: right;
  clear: right;
  z-index: 10;
  background-color: White;
}
/* Same as .floatcontent-right but to the left */
.floatcontent-left {
  padding: 0 1em 0 1em;
  margin: auto;
  float: left;
  clear: left;
  z-index: 10;
  background-color: White;
}

pre {
  display: block;
  font-family: Monaco, 'Courier New', Courier, monospace;
  white-space-collapse: preserve;
  text-wrap: nowrap;
  margin: 1em 0px;
}

.preformatted {
  display: block;
  font-family: Monaco, 'Courier New', Courier, monospace;
  white-space-collapse: preserve;
  text-wrap: nowrap;
  margin: 1em 0px;
}
.preserve-spacing {
  display: block;
  white-space-collapse: preserve;
  margin: 1em 0px;
}
