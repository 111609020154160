/* For DescriptionListTemplate container line: */
.listing-body dt {
  font-weight: bold;
}

/* Make listing blocks compact: */
.listing-item {
  border-bottom: none !important;
  margin-bottom: 0.75em !important;
  padding-bottom: 0px !important;
}
