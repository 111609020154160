/* I want the columnar format in the ui stackable menu always.
   Trying to track down how to do that. */
/* @media only screen and (max-width: 900px) .ui.stackable.menu { */
/*
@media only screen .ui.stackable.menu {
  flex-direction: column;
}
*/

.ui.dropdown .menu-title {
  font-weight: 500;
}

.mobile-menu-nav .ui.pointing.secondary.stackable.menu {
  /* So the navbar is always columnar on mobile.
     NOTE: This should be the Volto default! */
  flex-direction: column;
}

.navigation .ui.secondary.pointing.menu .item:not(.filtered),
.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
  display: flex !important;
  align-items: normal;
  min-width: 7em;
  max-width: 11em;
  white-space: normal;
  width: min-content;
  line-height: 1.1;
}

.ui.menu:not(.vertical) .item {
  align-items: top !important;
}

.ui.dropdown.item i.icon,
.ui.dropdown.item i.icons {
  font-size: 1em !important;           /* Make icon proportional to text. */
  padding: 0em !important;
}
.ui.menu .item > i.dropdown.icon {
  float: right;
}
.ui.menu .ui.dropdown.item .menu .item {
  display: flex !important;     /* Horizontally align icon with entry text. */
  padding: .2em !important;
}
.ui.menu .dropdown.item .menu {
  min-width: fit-content !important;   /* Snug menu to contents width. */
}
.navigation .ui.secondary.pointing.menu .item, .tools .ui.secondary.pointing.menu .item {
  padding: 0em !important;
  margin: 0em 7px 0em 0px !important;
  text-transform: none !important;  /* Don't normalize container titles */
}
.ui.menu .item > i.dropdown.icon {
  padding: 0em !important;
  margin: 0em !important;
}
.ui.dropdown .menu.self-container > .item:first-child {
  text-decoration: underline gray;
  /* More distinctive but might make the entries seem unselectable. */
  /*
    border: lightgray solid 2px;
    font-variant: petite-caps;
    */
}
.ui.secondary.menu .dropdown.item > .menu, .ui.text.menu .dropdown.item > .menu {
  /* Effectively eliminate gap between base menu element and offspring menu: */
  margin-top: 0px !important;
}
.ui.simple.dropdown .menu {
  /* Make menu fade-in discernable but still brief: */
  transition: opacity 0.4s ease !important;
}
.ui.menu .item {
  color: black !important;  /* Kludge so nested menu headers don't get pale. */
}
